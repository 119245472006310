* {
    background-color: #0d0b33;
    color: white;
}

.heading {
    text-align: center;
    font-size: 20px;
}

.topIcon {
    color: greenyellow;
    font-size: 50px;
}

.listimage {
    height: 100px;
    width: 80px;
}

.img-heading {
    text-align: center;
    margin-top: 5%;
}

.fireIcon {
    color: #FFA012;
}

.list-container {
    text-align: center;
}

.penIcon,
.plusIcon {
    background-color: white;
}

.plusIcon {
    font-weight: 900;
    cursor: pointer;
}

.plusIcon:hover {
    color: blue;
}

.checkbtn {
    margin-top: 3%;
    background-color: white;
    width: 130px;
    height: 45px;
    line-height: 45px;
    color: #8c8c8c;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}
.checkbtn:hover {
    background-color: rgb(206, 225, 241);
}

.listContent {
    width: 100%;
    margin-top: 2%;
}

.listItem {
    background-color: #6523FA;
    width: 30vw;
    height: 45px;
    margin-top: 1%;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.inputField {
    width: 30vw;
}
.listName {
    width: 70%;
    height: 45px;
    text-align: left;
    margin-left: 5%;
    font-size: 20px;
    line-height: 42px;
    background-color: #6523FA;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.listIcons {
    width: 30%;
    height: 45px;
    background-color: #6523FA;  
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 45px;
    font-size: 20px;
    display: flex;
    justify-content: right;
}
.editIcon {
    margin-top: 0.5%;
}
.performEditIcon {
    margin-left: 5%;
    background-color: white;
    cursor: pointer;
    font-weight: 900;
}
.editIcon, .deleteIcon {
    background-color: #6523FA;
    margin-right: 10%;
    cursor: pointer;
}
.editIcon:hover {
    color: rgb(153, 255, 0);
}
.deleteIcon:hover {
    color: red;
}

@media only screen and (max-width: 950px) {
    .inputField {
        width: 60vw;
    }
    .listItem {
        width: 60vw;
    }
    .tooltipVis
    {
        display: none;
    }
}
@media only screen and (max-width: 420px) {
    .inputField {
        width: 80vw;
    }
    .listItem {
        width: 80vw;
        margin-top: 2%;
    }
    .listContent {
        margin-top: 3%;
    }
}